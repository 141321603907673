export default {

  name: 'FreeAttribute',

  freeAttribute: {
    refNr: 0,
    name: '',
    type: 'BOOLEAN',
    booleanValue: true,
    stringValue: '',
    doubleValue: 0,
    intValue: 0,
  }
}