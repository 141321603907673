<template>
  <div v-if="freeAttributes">
    <md-content class="md-scrollbar">
      <div v-if="canManage">
        <md-button @click="onAddFreeAttribute" class="md-icon-button md-raised md-primary">
          <md-icon>add</md-icon>
          <md-tooltip>Neues Merkmal hinzufügen</md-tooltip>
        </md-button>
        <br><br>
      </div>
      <div v-if="freeAttributes.length <= 0">
        <p><i>Keine individuellen Merkmale angegeben.</i></p>
      </div>
      <div v-else>
        <div v-for="(attribute, index) of freeAttributes" :key="attribute.type + index" class="grid-x grid-padding-x">
          <div v-if="attribute.type === 'BOOLEAN'" class="cell small-8">
            <md-checkbox class="md-primary" v-model="attribute.booleanValue">{{ attribute.name }}</md-checkbox>
            <br>
          </div>
          <div v-if="canManage">
            <div v-if="attribute.type === 'BOOLEAN'" class="cell small-4 text-left">
              <md-button class="md-icon-button md-dense" @click="onEditFreeAttribute(index)">
                <md-icon>edit</md-icon>
                <md-tooltip>Merkmal <b>{{ attribute.name }}</b> bearbeiten</md-tooltip>
              </md-button>
              <md-button class="md-icon-button md-dense md-accent" @click="onDeleteFreeAttribute(index)">
                <md-icon>close</md-icon>
                <md-tooltip>Merkmal <b>{{ attribute.name }}</b> löschen</md-tooltip>
              </md-button>
            </div>
          </div>
        </div>
        <div v-for="(attribute, index) of freeAttributes" :key="attribute.name + index" class="grid-x grid-padding-x">
          <div v-if="attribute.type !== 'BOOLEAN'" class="cell small-8">
            <div v-if="attribute.type === 'STRING'">
              <md-field style="margin-bottom: -8px;">
                <label :for="attribute.name">{{ attribute.name }}</label>
                <md-input :name="attribute.name" :id="attribute.name" autocomplete="off"
                          v-model="attribute.stringValue"/>
              </md-field>
              <br>
            </div>
            <div v-if="['INTEGER', 'DOUBLE'].includes(attribute.type)">
              <md-field style="margin-bottom: -8px;">
                <label :for="attribute.name">{{ attribute.name }}</label>
                <md-input v-if="attribute.type === 'INTEGER'" :name="attribute.name" type="number" :id="attribute.name"
                          autocomplete="off" v-model="attribute.intValue"/>
                <md-input v-if="attribute.type === 'DOUBLE'" :name="attribute.name" type="number" :id="attribute.name"
                          autocomplete="off" v-model="attribute.doubleValue"/>
              </md-field>
              <br>
            </div>
          </div>
          <div v-if="canManage">
            <div v-if="attribute.type !== 'BOOLEAN'" class="cell small-4 text-left">
              <br v-if="['STRING', 'INTEGER', 'DOUBLE'].includes(attribute.type)">
              <md-button class="md-icon-button md-dense" @click="onEditFreeAttribute(index)">
                <md-icon>edit</md-icon>
                <md-tooltip>Merkmal <b>{{ attribute.name }}</b> bearbeiten</md-tooltip>
              </md-button>
              <md-button class="md-icon-button md-dense md-accent" @click="onDeleteFreeAttribute(index)">
                <md-icon>close</md-icon>
                <md-tooltip>Merkmal <b>{{ attribute.name }}</b> löschen</md-tooltip>
              </md-button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <br>
        <h6 v-if="!canManage">Im Bereich <a @click="onRouteToCustomization">Verwaltung / Anpassungen</a> können weitere
          individuelle Merkmale für Kinder definiert werden.</h6>
      </div>
    </md-content>

    <md-dialog :md-click-outside-to-close="false" v-if="canManage" :md-active.sync="showAddAttributeDialog">
      <div v-if="freeAttribute" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title>
          <md-icon>check</md-icon>&nbsp;&nbsp;&nbsp;Individuelles Merkmal
          <span v-if="attributeDialogMode === 'add'"> hinzufügen</span>
          <span v-if="attributeDialogMode === 'edit'"> bearbeiten</span>
          <span v-if="attributeDialogMode === 'delete'"> löschen</span>
        </md-dialog-title>
        <div class="cell text-center">
          <div v-if="attributeDialogMode === 'delete'">
            <p><b class="ai-alert-text">Achtung:</b> Dieses Merkmal wird für alle Kinder in dieser Einrichtung gelöscht.
            </p>
            <p>Soll das Merkmal <b>{{ freeAttribute.name }}</b> wirklich gelöscht werden?</p>
          </div>
          <div v-else class="grid-x grid-padding-x">
            <div class="cell small-1"></div>
            <div class="cell small-5">
              <md-field :class="getValidationClass('name')">
                <label>Name</label>
                <md-input name="attributeName" id="attributeName" autocomplete="off" v-model="freeAttribute.name"/>
                <span class="md-error" v-if="!$v.freeAttribute.name.required">Name ist notwendig</span>
                <span class="md-error" v-if="!$v.freeAttribute.name.valid">Unzulässige Zeichen enthalten</span>
                <md-tooltip>
                  Name für das Merkmal
                </md-tooltip>
              </md-field>
            </div>
            <div class="cell small-5">
              <md-field>
                <label>Datentyp</label>
                <md-select v-model="freeAttribute.type">
                  <md-option v-for="attributeType of labels.freeAttributeTypes" :value="attributeType"
                             :key="attributeType">
                    {{ labels.freeAttributeType[attributeType] }}
                  </md-option>
                </md-select>
                <md-tooltip>
                  Datentyp für das Merkmal
                </md-tooltip>
              </md-field>
            </div>
            <div class="cell small-1"></div>
          </div>
        </div>
        <div class="cell text-center">
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
          <md-checkbox v-if="attributeDialogMode === 'delete'" v-model="absolutelySure" :disabled="sending">Ich bin mir
            absolut sicher!
          </md-checkbox>
        </div>
        <div class="cell text-center">
          <button v-if="attributeDialogMode === 'add'" @click="onInsertFreeAttribute" class="button success"
                  :disabled="freeAttribute.name === '' || sending">
            <i class="fi-plus"></i>&nbsp;&nbsp;&nbsp;Merkmal jetzt hinzufügen
          </button>
          <button v-if="attributeDialogMode === 'edit'" @click="onUpdateFreeAttribute" class="button success"
                  :disabled="freeAttribute.name === '' || !$v.freeAttribute.name.valid || sending">
            <i class="fi-check"></i>&nbsp;&nbsp;&nbsp;Änderungen übernehmen
          </button>
          <button v-if="attributeDialogMode === 'delete'" @click="onRemoveFreeAttribute" class="button success"
                  :disabled="freeAttribute.name === '' || sending || !absolutelySure">
            <i class="fi-trash"></i>&nbsp;&nbsp;&nbsp;Ja, Merkmal jetzt löschen
          </button>
          <button class="button alert" style="margin-left: 1rem;" @click="showAddAttributeDialog = false"
                  type="button" :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Abbrechen
          </button>
        </div>
        <button class="close-button" type="button" @click="showAddAttributeDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import FreeAttribute from "@/entities/FreeAttribute";
import FacilityService from "@/services/FacilityService";
import HttpErrorHandler from "@/services/HttpErrorHandler";
import {helpers, not, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

const invalidCharacters = helpers.regex('invalidCharacters', /[^a-zA-Z äöüßÄÖÜ\-éèáàô0-9.,;()]+/);

export default {
  name: 'FreeAttributesForm',
  mixins: [validationMixin],

  props: {
    freeAttributes: {
      required: true,
    },
    canManage: {
      required: false,
    },
    facilityReferenceNumber: {
      required: false,
    }
  },

  data: () => ({
    sending: false,
    absolutelySure: false,
    showAddAttributeDialog: false,
    freeAttribute: null,
    freeAttributeIndex: 0,
    attributeDialogMode: 'add',
  }),

  validations: {
    freeAttribute: {
      name: {
        required,
        valid: not(invalidCharacters),
      },
    },
  },

  methods: {
    getValidationClass(fieldName) {
      let field = this.$v.freeAttribute[fieldName];

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },

    validateFreeAttribute() {
      this.$v.$touch();
      this.invalid = this.$v.$invalid;
    },

    onAddFreeAttribute() {
      this.freeAttribute = JSON.parse(JSON.stringify(FreeAttribute.freeAttribute));
      this.attributeDialogMode = 'add';
      this.sending = false;
      this.showAddAttributeDialog = true;
      this.$v.$reset();
    },

    onInsertFreeAttribute() {
      this.validateFreeAttribute();
      if (this.$v.freeAttribute.name.valid && this.facilityReferenceNumber) {
        this.addFreeAttribute();
      }
    },

    addFreeAttribute() {
      this.sending = true;
      this.success = false;
      FacilityService.addFreeAttribute(this.facilityReferenceNumber, [this.freeAttribute])
          .then((response) => {
            this.sending = false;
            this.showAddAttributeDialog = false;
            this.$emit("updateFacility", response.data);
            this.$store.commit('successMsg', 'Das neue Merkmal <b>' + this.freeAttribute.name + '</b> wurde erfolgreich hinzugefügt.');
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Anlagen des Merkmals <b>' + this.freeAttribute.name + '</b>.');
            this.sending = false;
          })
    },

    onEditFreeAttribute(index) {
      if (index < this.freeAttributes.length) {
        this.freeAttribute = JSON.parse(JSON.stringify(this.freeAttributes[index]));
        this.freeAttributeIndex = index;
        this.attributeDialogMode = 'edit';
        this.sending = false;
        this.showAddAttributeDialog = true;
      }
    },

    onUpdateFreeAttribute() {
      if (this.$v.freeAttribute.name.valid) {
        this.showAddAttributeDialog = false;
        this.updateFreeAttribute();
      }
    },

    updateFreeAttribute() {
      this.sending = true;
      FacilityService.updateFreeAttribute(this.facilityReferenceNumber, [this.freeAttribute])
          .then((response) => {
            this.sending = false;
            this.showAddAttributeDialog = false;
            this.$emit("updateFacility", response.data);
            this.$store.commit('successMsg', 'Das Merkmal <b>' + this.freeAttribute.name + '</b> wurde erfolgreich geändert.');
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Ändern des Merkmals <b>' + this.freeAttribute.name + '</b>.');
            this.sending = false;
            this.showAddAttributeDialog = false;
          })
    },

    onDeleteFreeAttribute(index) {
      if (index < this.freeAttributes.length) {
        this.freeAttribute = this.freeAttributes[index];
        this.freeAttributeIndex = index;
        this.attributeDialogMode = 'delete';
        this.sending = false;
        this.absolutelySure = false;
        this.showAddAttributeDialog = true;
      }
    },

    onRemoveFreeAttribute() {
      if (this.facilityReferenceNumber) {
        this.deleteFreeAttribute();
      }
    },

    deleteFreeAttribute() {
      this.sending = true;
      FacilityService.deleteFreeAttribute(this.facilityReferenceNumber, [this.freeAttribute.refNr])
          .then((response) => {
            this.sending = false;
            this.showAddAttributeDialog = false;
            this.$emit("updateFacility", response.data);
            this.$store.commit('successMsg', 'Das Merkmal <b>' + this.freeAttribute.name + '</b> wurde erfolgreich gelöscht.');
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen des Merkmals <b>' + this.freeAttribute.name + '</b>.');
            this.sending = false;
            this.showAddAttributeDialog = false;
          })
    },

    onRouteToCustomization() {
      this.$emit("saveAndExitTo", '/Verwaltung/Anpassungen');
    },
  },

  computed: {
    labels() {
      return this.$store.getters.labels
    },
  }

};

</script>

<style lang="scss" scoped>
.md-dialog /deep/ .md-dialog-container {
  max-width: 800px;
}

.md-tooltip {
  font-size: medium;
  white-space: normal;
}

.md-dialog p {
  font-size: medium;
}

.md-checkbox {
  margin: 5px;
}
</style>
