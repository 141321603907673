import HttpInterceptor from './HttpInterceptor'

export default {

  name: 'FacilityService',

  getAllFacilities() {
    return HttpInterceptor.httpClient().get('sage/facility/all')
  },

  getAvailableTaxCertificates4Year(facilityReferenceNumber, year) {
    return HttpInterceptor.httpClient().get('facility/' + facilityReferenceNumber + '/' + year + '/taxcertificate/children')
  },

  updateFacility(facility) {
    return HttpInterceptor.httpClient().put('facility/update', facility)
  },

  deleteFacility(referenceNumber) {
    return HttpInterceptor.httpClient().delete('facility/' + referenceNumber)
  },

  deleteBookingTimeTemplate(referenceNumber, templateName) {
    return HttpInterceptor.httpClient().delete('facility/' + referenceNumber + '/bookingtimetemplate?templateName=' + templateName)
  },

  addGroupToFacility(facilityReferenceNo, group) {
    return HttpInterceptor.httpClient().post('facility/' + facilityReferenceNo + '/group', group)
  },

  addChildToFacility(facilityReferenceNo, child) {
    return HttpInterceptor.httpClient().post('facility/' + facilityReferenceNo + '/child/new', child)
  },

  addEmployeeToFacility(facilityReferenceNo, employee) {
    return HttpInterceptor.httpClient().post('facility/' + facilityReferenceNo + '/employee/new', employee)
  },

  addPriceList(facilityReferenceNo, priceList) {
    return HttpInterceptor.httpClient().post('facility/' + facilityReferenceNo + '/pricelist/new', priceList)
  },

  addFeeTemplate(facilityReferenceNo, feeTemplate) {
    return HttpInterceptor.httpClient().post('facility/' + facilityReferenceNo + '/feetemplate/new', feeTemplate)
  },

  updateTemplate(facilityReferenceNo, template) {
    return HttpInterceptor.httpClient().post('facility/' + facilityReferenceNo + '/template/update', template)
  },

  deleteTemplate(facilityReferenceNo, template) {
    return HttpInterceptor.httpClient().delete('facility/' + facilityReferenceNo + '/template/delete', { data: template})
  },

  importExcelPhase2(facilityReferenceNo, mapping) {
    return HttpInterceptor.httpClient().post('facility/excel/import/phase2/' + facilityReferenceNo, mapping)
  },

  importExcelPhase3(importResult) {
    return HttpInterceptor.httpClient().post('facility/excel/import/phase3', importResult)
  },

  calcKibigData(facilityReferenceNo, year, kigaYear) {
    return HttpInterceptor.httpClient().get('facility/' + facilityReferenceNo + '/kibig/calc/' + year + '?kigaYear=' + kigaYear)
  },

  simulateKibigData(facilityReferenceNo, year, simulationData) {
    return HttpInterceptor.httpClient().post('facility/' + facilityReferenceNo + '/kibig/sim/' + year, simulationData)
  },

  uploadKibigData(facilityReferenceNo, year, uploadConfig) {
    return HttpInterceptor.httpClient().post('facility/' + facilityReferenceNo + '/kibig/' + year + '/upload', uploadConfig)
  },

  getKibigXML(facilityReferenceNo, year, uploadConfig) {
    let config = {
      headers: {
        'content-type': 'application/json',
        'accept': 'application/xml',
        'Authorization': 'Bearer ' + sessionStorage.getItem('sose.access_token'),
      },
    };

    return HttpInterceptor.httpClient().post('facility/' + facilityReferenceNo + '/kibig/' + year, uploadConfig, config)
  },

  getClosingTimes4Year(facilityReferenceNo, year) {
    return HttpInterceptor.httpClient().get('facility/' + facilityReferenceNo + '/closingtime/' + year)
  },

  getPersonChildRelation(facilityReferenceNo) {
    return HttpInterceptor.httpClient().get('facility/' + facilityReferenceNo + '/personchildrelation')
  },

  getPriceLists(facilityReferenceNo) {
    return HttpInterceptor.httpClient().get('facility/' + facilityReferenceNo + '/pricelists')
  },

  getFeeTemplates(facilityReferenceNo) {
    return HttpInterceptor.httpClient().get('facility/' + facilityReferenceNo + '/fee/templates')
  },

  getStatistic(facilityReferenceNo, date) {
    return HttpInterceptor.httpClient().get('facility/' + facilityReferenceNo + '/statistic/' + date)
  },

  getAssignmentPlan(facilityReferenceNo, year, month) {
    return HttpInterceptor.httpClient().get('facility/' + facilityReferenceNo + '/assignmentplan/' + year + '/' + month)
  },

  getAbsenceOverview(facilityReferenceNo, startDate, endDate) {
    return HttpInterceptor.httpClient().get('facility/' + facilityReferenceNo + '/' + startDate +  '/' + endDate + '/absences')
  },

  getFilteredChildren(filterSettings) {
    return HttpInterceptor.httpClient().put('facility/children/filtered', filterSettings)
  },

  addClosingTime(facilityReferenceNo, year, closingTime) {
    return HttpInterceptor.httpClient().post('facility/' + facilityReferenceNo + '/' + year + '/closingtime/new', closingTime)
  },

  transferToFacility(facilityReferenceNo, transferInfo) {
    return HttpInterceptor.httpClient().post('facility/' + facilityReferenceNo + '/child/transition', transferInfo)
  },

  moveToFacility(facilityReferenceNo, moveInfo) {
    return HttpInterceptor.httpClient().post('facility/' + facilityReferenceNo + '/child/move', moveInfo)
  },

  updateClosingTime(facilityReferenceNo, closingTimeRefNo, closingTime) {
    return HttpInterceptor.httpClient().put('facility/' + facilityReferenceNo + '/closingtime/' + closingTimeRefNo + '/update', closingTime)
  },

  updatePriceList(facilityReferenceNo, priceList, updateAssignedFees) {
    return HttpInterceptor.httpClient().put('facility/' + facilityReferenceNo + '/pricelist/update?updateAssignedFees=' + updateAssignedFees, priceList)
  },

  updateFeeTemplate(facilityReferenceNo, feeTemplate) {
    return HttpInterceptor.httpClient().put('facility/' + facilityReferenceNo + '/feetemplate/update', feeTemplate)
  },

  declareSiblings(childIds, areSiblings, targetFacRef) {
    if (targetFacRef === null) {
      targetFacRef = '';
    }
    return HttpInterceptor.httpClient().put('facility/siblings/declare?areSiblings=' + areSiblings + '&targetFacRef=' + targetFacRef, childIds)
  },

  deleteClosingTime(facilityReferenceNo, closingTimeRefNo) {
    return HttpInterceptor.httpClient().delete('facility/' + facilityReferenceNo + '/closingtime/' + closingTimeRefNo + '/delete')
  },

  deletePriceList(facilityReferenceNo, refNr) {
    return HttpInterceptor.httpClient().delete('facility/' + facilityReferenceNo + '/pricelist/' + refNr + '/delete')
  },

  deleteFeeTemplate(facilityReferenceNo, refNr) {
    return HttpInterceptor.httpClient().delete('facility/' + facilityReferenceNo + '/feetemplate/' + refNr + '/delete')
  },

  addFreeAttribute(facilityReferenceNo, freeAttributes) {
    return HttpInterceptor.httpClient().post('facility/' + facilityReferenceNo + '/freeAttribute/new', freeAttributes)
  },

  updateFreeAttribute(facilityReferenceNo, freeAttributes) {
    return HttpInterceptor.httpClient().put('facility/' + facilityReferenceNo + '/freeAttribute/update', freeAttributes)
  },

  deleteFreeAttribute(facilityReferenceNo, freeAttributes) {
    return HttpInterceptor.httpClient().post('facility/' + facilityReferenceNo + '/freeAttribute/delete', freeAttributes)
  },
}
