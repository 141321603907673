<template>
  <div v-if="isAuthenticated && organization">
    <div v-if="organization.baseModuleActive">
      <div class="grid-x grid-padding-x">
        <div class="cell callout success">
          <h3>
            <i class="fi-puzzle"></i>&nbsp;&nbsp;&nbsp;Individuelle Anpassungen
          </h3>
        </div>
      </div>

      <div v-if="organization && organization.facilities && organization.facilities.length <= 0"
           class="grid-x grid-padding-x grid-padding-y">
        <NoFacilityCard functionTitle="Anpassungen"></NoFacilityCard>
      </div>
      <div v-else class="grid-x grid-padding-x grid-padding-y">
        <div class="cell">
          <md-card>
            <md-card-content>
              <div class="grid-x grid-padding-x">
                <div v-if="availableFacilities && availableFacilities.length > 0"
                     class="cell large-3 medium-6 small-12">
                  <md-field>
                    <label>Einrichtung</label>
                    <md-select v-model="selectedFacilityReferenceNumber"
                               :disabled="sending || availableFacilities.length <= 1"
                               @md-selected="onSelectFacility">
                      <md-option v-for="facility of availableFacilities" :value="facility.referenceNumber"
                                 :key="facility.referenceNumber">
                        {{ facility.longName }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
                <div class="cell large-1 medium-1 small-0">
                  <div v-if="sending">
                    <vue-simple-spinner></vue-simple-spinner>
                  </div>
                </div>
              </div>
              <div class="grid-x grid-padding-x">
              <div class="cell small-12">
                <md-tabs md-elevation="1" md-dynamic-height>
                  <md-tab md-label="Kinder Merkmale">
                    <div class="grid-x grid-padding-x">
                      <div v-if="selectedFacility && selectedFacility.facilitySettings" class="cell large-6 medium-12 small-12">
                        <p class="md-title">Individuelle Merkmale für Kinder
                          <md-tooltip>Legen Sie hier weitere frei definierbare Merkmale für Kinder in der Einrichtung <b>{{selectedFacilityName}}</b> fest.</md-tooltip>
                        </p>
                        <br>
                        <FreeAttributesForm :freeAttributes="selectedFacility.facilitySettings.freeAttributes"
                                            :can-manage="canWrite && (isAdmin || isFacilityAdmin || isManagement)"
                                            :facilityReferenceNumber="selectedFacility.referenceNumber"
                                            @updateFacility="onUpdateFacility">
                        </FreeAttributesForm>
                      </div>
                    </div>
                  </md-tab>
                </md-tabs>
              </div>
            </div>
            </md-card-content>
          </md-card>
        </div>
      </div>

    </div>
    <div v-else style="padding: 1rem">
      <NoModuleCard moduleType="BASE" moduleFunction="Daten-Exporten"></NoModuleCard>
    </div>
  </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
import HttpErrorHandler from '../services/HttpErrorHandler';
import NoFacilityCard from "../components/cards/NoFacilityCard";

import BugReport from "../components/fabs/BugReport";
import {validationMixin} from "vuelidate";
import BetaFunctionCard from "../components/cards/BetaFunctionCard";
import NoModuleCard from '../components/cards/NoModuleCard';
import FreeAttributesForm from "../components/forms/FreeAttributesForm";

export default {
  name: 'Customizations',
  mixins: [validationMixin],
  components: {
    BetaFunctionCard,
    NoModuleCard,
    BugReport,
    'vue-simple-spinner': Spinner,
    NoFacilityCard,
    FreeAttributesForm,
  },

  mounted() {
    this.restoreUserSettings();
    this.reloadData();

    HttpErrorHandler.maintainDarkMode(this);
  },

  data() {
    return {
      sending: false,
      selected: null,
      success: false,
      availableFacilities: [],
      availableFacilitiesMap: {},
      selectedFacilityReferenceNumber: null,
      selectedFacilityReferenceNumbers: [],
      selectedFacilityName: '',
      selectedFacility: null,
    }
  },

  methods: {

    restoreUserSettings() {
      this.selectedFacilityReferenceNumber = localStorage.getItem(this.user.md5 + '@selectedFacilityReferenceNumber');
    },

    reloadData() {
      let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
      const reloadIntervalId = setInterval(() => {
        if (this.dataAvailable) {

          if (!this.isAdmin) {
            if (!this.selectedFacilityReferenceNumber) {
              this.selectedFacilityReferenceNumber = this.user.allowedFacilities[0].referenceNumber;
            }
          }

          this.getAllOrganizationFacilities();

          this.sending = false;
          clearInterval(reloadIntervalId);
        } else {
          this.sending = true;
          maxTries--;
          if (maxTries <= 0) {
            this.sending = false;
            clearInterval(reloadIntervalId);
            HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
          }
        }
      }, 250);
    },

    getAllOrganizationFacilities() {
      this.availableFacilities = this.getAvailableFacilities;
      this.buildAvailableFacilities();
    },

    getValidationClass(fieldName) {
      const field = this.$v.credentials[fieldName];
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },

    onSelectFacility() {
      if (this.selectedFacilityReferenceNumber && this.selectedFacilityReferenceNumber !== '' && this.selectedFacilityReferenceNumber !== 'undefined') {

        for (let facility of this.availableFacilities) {
          if (facility.referenceNumber === this.selectedFacilityReferenceNumber) {
            this.selectedFacilityName = facility.name;
            this.selectedFacility = facility;
          }
        }

        if (this.selectedFacility) {
          this.kibigCredentialsStored = this.selectedFacility.kibigData.kibigCredentialsStored;
        }
      }
    },

    buildAvailableFacilities() {
      for (let i = 0; i < this.availableFacilities.length; i++) {
        if (this.availableFacilities[i].referenceNumber === '*') {
          this.availableFacilities = this.availableFacilities.slice(0, i).concat(this.availableFacilities.slice(i + 1, this.availableFacilities.length));
        }
      }
      if (!this.selectedFacilityReferenceNumber && this.availableFacilities.length > 0) {
        this.selectedFacilityReferenceNumber = this.availableFacilities[0].referenceNumber;
        this.selectedFacilityName = this.availableFacilities[0].name;
      }

      this.onSelectFacility();
    },

    onUpdateFacility(updatedFacility) {
      let updatedOrganization = this.organization;

      updatedOrganization.facilities.forEach((facility, index) => {
        if (facility.referenceNumber === updatedFacility.referenceNumber) {
          updatedOrganization.facilities[index] = updatedFacility;
        }
      })

      this.$store.commit('organizationInfo', updatedOrganization);
      this.$store.commit('availableFacilities');
      this.availableFacilities = this.$store.getters.availableFacilities;
      this.buildAvailableFacilities();
    },
  },

  computed: {
    dataAvailable() {
      return this.organization && this.organization.facilities && this.user &&
          this.user.allowedFacilities && this.user.allowedFacilities.length > 0 && this.labels &&
          this.getAvailableFacilities && this.getAvailableFacilities.length > 0;
    },

    isAuthenticated() {
      return this.$store.getters.authenticated;
    },

    isAdmin() {
      return this.$store.getters.admin;
    },

    isFacilityAdmin() {
      return this.$store.getters.facilityAdmin;
    },

    isManagement() {
      return this.$store.getters.management;
    },

    canWrite() {
      return this.$store.getters.canWrite;
    },

    organization() {
      return this.$store.getters.organization;
    },

    user() {
      return this.$store.getters.user;
    },

    labels() {
      return this.$store.getters.labels
    },

    getAvailableFacilities() {
      return this.$store.getters.availableFacilities;
    },
  }
}
</script>

<style lang="scss" scoped>
i {
  color: black;
}

.dialog-title {
  color: cornflowerblue;
  font-size: larger;
}


.md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
  background: darkseagreen;
}

.md-tooltip {
  font-size: medium;
  height: auto;
  white-space: pre-wrap;
}


table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

tbody th, tbody tr, tbody td {
  padding: 3px 5px;
  text-align: center;
  // font-size: xx-small;
}

thead th, thead tr, thead td {
  padding: 3px 5px;
  text-align: center;
  // font-size: xx-small;
}

tbody tr:nth-child(even) {
  border-bottom: 0;
  background-color: transparent;
}

.warn {
  color: red;
}
</style>
