<template>
  <div>
    <md-card md-with-hover class="text-left">
      <md-card-header>
        <div class="md-layout">
          <div class="md-layout-item md-size-15">
            <md-icon class="md-size-2x">access_time</md-icon>
          </div>
          <div class="md-layout-item">
            <div class="md-title">BETA-Version</div>
            <div class="md-subhead">Die Funktion <span>{{functionTitle}}</span>&nbsp;befindet sich derzeit in einer Beta-Version und steht daher noch nicht zur Verfügung.</div>
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-15">
          </div>
          <div class="md-layout-item">
            Der Funktionumfang wird derzeit in der Produktionsumgebung getestet.
            Sobald die finale Abnahme und Freigabe erfolgt steht die Funktion zur Verfügung.<br>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
  export default {
    name: 'BetaFunctionCard',
    props: {
      functionTitle: {
        required: false
      },
    }
  }
</script>

<style lang="scss" scoped>
  span {
    color: cornflowerblue;
    font-weight: bold;
  }

  .md-title {
    color: coral;
    padding-bottom: 1rem;
  }
</style>
